<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <center>
        <!-- logo -->
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1">
            {{projectName}}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Activate your account 🔒
        </b-card-title>
        <b-card-text class="mb-2" v-if="step==1">
          Please wait while we're validating your request...<br /><br />
          
            <b-spinner variant="primary" label="Text Centered" />
        
        </b-card-text>
        <b-card-text class="mb-2" v-if="step==2">
          Please choose a password to login to your account
        </b-card-text>

        
        <!-- form -->
        <validation-observer ref="newPassword" v-if="step==2">
          <b-form
          method="POST"
          class="auth-reset-password-form mt-2"
          @submit.prevent="validationForm"
          >
          
          <!-- password -->
          <b-form-group
          label="New Password (min. 8 characters)"
          label-for="reset-password-new"
          >
          <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
                >
                <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
                >
                <b-form-input
                id="reset-password-new"
                v-model="password"
                :type="password1FieldType"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="reset-password-new"
                placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                  class="cursor-pointer"
                  :icon="password1ToggleIcon"
                  @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <!-- confirm password -->
          <b-form-group
          label-for="reset-password-confirm"
          label="Confirm Password"
          >
          <validation-provider
          #default="{ errors }"
          name="Confirm Password"
          rules="required|confirmed:Password"
          >
          <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
          >
          <b-form-input
          id="reset-password-confirm"
          v-model="cPassword"
          :type="password2FieldType"
          class="form-control-merge"
          :state="errors.length > 0 ? false:null"
          name="reset-password-confirm"
          placeholder="············"
          />
          <b-input-group-append is-text>
            <feather-icon
            class="cursor-pointer"
            :icon="password2ToggleIcon"
            @click="togglePassword2Visibility"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    
    <!-- submit button -->
    <b-button
    block
    type="submit"
    variant="primary"
    >
    <b-spinner small v-if="isSettingPassword"/>
    <template v-if="!isSettingPassword">
      Set password
    </template>
    <template v-else>
      Setting password...
    </template>
    
  </b-button>
</b-form>
</validation-observer>

<b-card-text class="mb-2" v-if="step==3">
  Please scan this QR-code with your authenticator app and enter the verification code.<br><br>
  <strong>Authenticator apps:</strong><br>
    Microsoft Authenticator (<a target="_blank" href="https://www.microsoft.com/en-gb/security/mobile-authenticator-app">Android</a> | <a target="_blank" href="https://www.microsoft.com/en-gb/security/mobile-authenticator-app">iOS</a>)<br>
    Google Authenticator (<a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=UK">Android</a> | <a target="_blank" href="https://apps.apple.com/nl/app/google-authenticator/id388497605">iOS</a>)
</b-card-text>


<b-card-text class="mb-2" v-if="step==3">
  <img :src="qr.encodedImage">
</b-card-text>

<b-input-group
    v-if="step==3"
    class="input-group-merge mb-1"
    >
    <b-form-input
      v-model="verificationCode"
      class="form-control-merge"
      placeholder="Verification code"
      />
      
</b-input-group>

    <!-- submit button -->
    <b-button
    v-if="step==3"
    block
    type="submit"
    variant="primary"
    @click="verify"
    >
    <b-spinner small v-if="isVerifying"/>
    <template v-if="!isVerifying">
      Verify
    </template>
    <template v-else>
      Please wait...
    </template>
    
  </b-button>

        <p class="text-center mt-2" v-if="step!=1">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </center>
      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BSpinner, BRow, BCard, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      step:1,
      isSettingPassword:false,
      isVerifying:false,
      verificationCode:'',
      qr:{
        encodedImage:''
      },
      status: '',
      cPassword: '',
      password: '',
      newPassword: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
        this.sideImg = require('@/assets/images/logo/physiocheck_admin_logo.png')
        return this.sideImg
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async activate() {
      console.log(this.$route)
      
      const res = await this.callApi('post', '/login/activate', {
          id:this.$route.params.id,
          code:this.$route.params.code
      });
      if (res.data.result.success) {
        this.step=2;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation success!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }
      else {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Activation failed',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
      }

    },
    
    async setPassword() {
      if (this.isSettingPassword) return;
      this.isSettingPassword = true;
      const res = await this.callApi('post', '/login/activatePassword', {
          id:this.$route.params.id,
          code:this.$route.params.code,
          password:this.password,
      });
      if (res.data.result.success) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Password saved',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
        this.step=3;
        if (res.data.result.qrCodeImage) {
          this.qr.encodedImage = res.data.result.qrCodeImage;
        }
        else {
            this.$router.push('/login')
        }

      }
    },
    async verify() {
      if (this.isVerifying) return;
      this.isVerifying = true;
      const res = await this.callApi('post', '/login/verifyCode', {
          id:this.$route.params.id,
          code:this.verificationCode,
          
      });
      if (res.data.result.success) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Account verified',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
            this.$router.push('/login')

      }
      else {
        this.isVerifying = false;
        this.verificationCode = '';

        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Verification error!',
            icon: 'EditIcon',
            variant: 'error',
          },
        })
      }
    },
    validationForm() {

      this.$refs.newPassword.validate().then(success => {
        if (success) {
          // save password
          this.setPassword();
        }
      })
    },
  },
  mounted() {
    this.activate();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
